import React, { FC, useState } from 'react';
import Button from '../../components/Button';
import styles from './VisitorCounter.module.css';

interface Props {
  capacity: number
}

const VisitorCounter: FC<Props> = ({ capacity }) => {
  const [visitors, setVisitors] = useState(0);



  const removeVisitor = () => {
    if(visitors > 0) {
      setVisitors((c) => c - 1);
    }
  }

  const addVisitor = () => {
    if(visitors < Number(capacity)) {
      setVisitors((c) => c + 1);
    }
  }

  return (
    <div className={styles.container}>
      <p>Your venue currently has {visitors} visitors</p>
      <p className={styles.instructions}>Update the venue's current visitor count with the buttons below.</p>
      <div className={styles.btnGroup}>
        <Button
          onClick={removeVisitor}
          variant="secondary"
        >
          Remove Visitor
        </Button>
        <Button
          onClick={addVisitor}
          variant="secondary"
        >
          Add Visitor
        </Button>
      </div>
    </div>
  );
}

export default VisitorCounter;
