import React, { FC, useState } from 'react';
import CapacitySetter from '../CapacitySetter';
import VisitorCounter from '../VisitorCounter';
import styles from './App.module.css';

const App: FC = () => {
  const [capacity, setCapacity] = useState("");
  const [isCapacitySet, setIsCapacitySet] = useState(false);
  const [showError, setShowError] = useState(false);

  const startCapacityCounter = () => {
    const numberCapacity = Number(capacity);
    if (!isNaN(numberCapacity) && numberCapacity > 0) {
      setIsCapacitySet(true);
    } else {
      setShowError(true);
    }
  }

  return (
    <div className={styles.container}>
      <h1>Capacity Counter</h1>
      {!isCapacitySet && (
        <CapacitySetter
          capacity={capacity}
          setCapacity={setCapacity}
          startCapacityCounter={startCapacityCounter}
        />
      )}
      {!isCapacitySet && showError && (
        <p>The capacity you have supplied isn't valid. Please enter a positive number.</p>
      )}
      {isCapacitySet && (
        <VisitorCounter capacity={Number(capacity)} />
      )}
    </div>
  );
}

export default App;
