import React, { FC } from 'react';
import Button from '../../components/Button';
import styles from './CapacitySetter.module.css';

interface Props {
  capacity: string
  setCapacity: React.Dispatch<React.SetStateAction<string>>
  startCapacityCounter: () => void
}

const CapacitySetter: FC<Props> = ({ capacity, setCapacity, startCapacityCounter }) => (
  <div className={styles.container}>
    <p>Please provide your venue's capacity.</p>
    <div className={styles.capacityForm}>
      <div className={styles.capacityField}>
        <label
          htmlFor="capacity"
          className={styles.label}
        >
          Capacity
        </label>
        <input
          id="capacity"
          inputMode="numeric"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
        />
      </div>
      <div className={styles.btnGroup}>
        <Button
          onClick={startCapacityCounter}
          variant="primary"
          className={styles.startBtn}
        >
          Start
        </Button>
      </div>
    </div>
  </div>
);

export default CapacitySetter;
